import { createTRPCClient, httpLink } from '@trpc/client'
import type { AppRouter } from '../../../api-zulassung-digital/trpc/routers'
import superjson from 'superjson'

export type TRPCClient = ReturnType<typeof createTRPCClient<AppRouter>>

export default defineNuxtPlugin(() => {
  const trpc = createTRPCClient<AppRouter>({
    links: [
      httpLink({
        url: `${useRuntimeConfig().public.backendUrl}/trpc`,
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: 'include',
          })
        },
        transformer: superjson
      })
    ],
  })

  return {
    provide: {
      trpc
    }
  }
})
