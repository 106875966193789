import revive_payload_client_AHJxFBsaHU from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qV3EvXfNxt from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JtTDEufWRZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8xgBeLOlBS from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Q3t1kbKiz9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TIhtPh1OuP from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EmZe6apYyc from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/abmeldung-digital/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_SMlKxaHFqK from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/abmeldung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_hgMHkjHhNS from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_ji_qi3ewfedxjmvzvez4mgxcr5wyq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_spU1DdQDf3 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.7_async-validator@4.2.5_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast_fjnrnqedjfznucxtknsjrohvmy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_s2Ft9xf8JA from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.7_async-validator@4.2.5_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast_fjnrnqedjfznucxtknsjrohvmy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_sMZRo1RDsj from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.7_async-validator@4.2.5_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast_fjnrnqedjfznucxtknsjrohvmy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_82idpn4vc7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_QFub2GyZ0E from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.6.1_magicast@0.3.5_rollup@4.24.4_vite@5.4.10_@types+node@20.9.0_sass-embedded@1._7655mljhqzk2nwwdu4lfernwsi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_e7wRZXBwqF from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.4_typesc_azxgwed4o26fjnjq2xaz7sd3cm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import event_directive_YCbI7d1p4o from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_JCMvpbgZ2W from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import feathers_r9RHv5VuHz from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_ZWaWEo2Woe from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_EGbfpheBQT from "/vercel/path0/layers/base/plugins/trpc.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/abmeldung-digital/plugins/sentry.client.ts";
export default [
  revive_payload_client_AHJxFBsaHU,
  unhead_qV3EvXfNxt,
  router_JtTDEufWRZ,
  payload_client_8xgBeLOlBS,
  navigation_repaint_client_Q3t1kbKiz9,
  check_outdated_build_client_TIhtPh1OuP,
  chunk_reload_client_EmZe6apYyc,
  plugin_zbacBAXhj0,
  plugin_vue3_SMlKxaHFqK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hgMHkjHhNS,
  slideovers_spU1DdQDf3,
  modals_s2Ft9xf8JA,
  colors_sMZRo1RDsj,
  plugin_client_82idpn4vc7,
  plugin_QFub2GyZ0E,
  plugin_e7wRZXBwqF,
  event_directive_YCbI7d1p4o,
  script_JCMvpbgZ2W,
  feathers_r9RHv5VuHz,
  cookie_banner_client_ZWaWEo2Woe,
  trpc_EGbfpheBQT,
  sentry_client_shVUlIjFLk
]